/** @format */
import { useMutation } from "@apollo/client";
import { wwwSignupValidationSchema } from "@roadflex/constants";
import { SIGNUP_VERIFY } from "@roadflex/graphql";
import { SignupValues } from "@roadflex/types";
import { useFormik } from "formik";

type UseSignUpProps = {
  onSubmit: (err: Error | null) => void;
  initialValues: SignupValues;
};

export const useSignup = ({ onSubmit, initialValues }: UseSignUpProps) => {
  const [signupVerifyFn] = useMutation(SIGNUP_VERIFY);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    // handleReset,
    values,
    // submitForm,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: wwwSignupValidationSchema,
    onSubmit: async (value) => {
      try {
        const { productName, otp, ...data } = value;
        await signupVerifyFn({
          variables: {
            data: {
              ...data,
              phoneNumber: data.phoneNumber?.trim()?.match(/\d+/g)?.join(""),
            },
          },
        });

        onSubmit(null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(error);
        } else {
          onSubmit(new Error("Something went wrong"));
        }
      }
    },
  });
  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
