/** @format */

import { usePlatformDetect } from "@roadflex/react-hooks-www";
import { ResetPasswordValues } from "@roadflex/types";
import classNames from "classnames";
import { FormikProps } from "formik";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { TextInput } from "../../inputs";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResetPasswordUIProps extends FormikProps<ResetPasswordValues> {}

function ResetPasswordUI({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  errors,
  touched,
  handleBlur,
}: ResetPasswordUIProps) {
  const { isMobile } = usePlatformDetect();
  return (
    <div
      className={classNames(
        isMobile() ? "flex-col" : "flex-row h-[calc(100vh-64px)]",
        "flex w-full",
      )}
    >
      <div className="w-full text-center text-white py-14 md:block md:py-28 bg-brown-800">
        <div className={`flex flex-col justify-center h-full animate-slidein`}>
          <div className="mb-3 text-2xl font-semibold md:text-4xl">
            <h1 className="">Reset password</h1>
            <div className="p-3 text-base md:text-xl">
              Choose a new password
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center w-full text-center rounded-md">
        <form
          className="flex flex-col px-4 py-8 md:p-16"
          onSubmit={handleSubmit}
        >
          <div className="relative w-full">
            <TextInput
              label="Password"
              type="password"
              required
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && Boolean(errors.password)}
              errorMessage={errors.password}
              touched={touched.password}
              variant="responsive"
            ></TextInput>
          </div>
          <div className="relative w-full mt-4">
            <TextInput
              label="Confirm Password"
              type="password"
              required
              name="retypePassword"
              value={values.retypePassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.retypePassword && Boolean(errors.retypePassword)}
              errorMessage={errors.retypePassword}
              touched={touched.retypePassword}
              variant="responsive"
            ></TextInput>
          </div>

          <div className="mt-12 text-right">
            <Button
              type={ButtonType.Submit}
              variant={ButtonVariant.Orange}
              size={ButtonSize.Normal}
              className="w-full"
              disabled={isSubmitting}
            >
              Create
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPasswordUI;
