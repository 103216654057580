/** @format */
import { useMutation } from "@apollo/client";
import { genericSignInValidationSchema } from "@roadflex/constants";
import { SIGN_IN } from "@roadflex/graphql";
import { SignInValues } from "@roadflex/types";
import { useFormik } from "formik";

interface UseSignInProps {
  onSubmit: (val: { authToken: string } | null, error: Error | null) => void;
  initialValues: SignInValues;
}

export const useSignin = ({ onSubmit, initialValues }: UseSignInProps) => {
  const [signInFn] = useMutation(SIGN_IN, {
    fetchPolicy: "no-cache",
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: genericSignInValidationSchema,
    onSubmit: async (value) => {
      try {
        const res = await signInFn({
          variables: { data: value },
        });
        onSubmit(res?.data?.signin, null);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, { message: "Something Went Wrong", name: "" });
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
