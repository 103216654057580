/** @format */

import { usePlatformDetect } from "@roadflex/react-hooks-www";
import { ForgotPasswordValues } from "@roadflex/types";
import classNames from "classnames";
import { FormikProps } from "formik";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { TextInput } from "../../inputs";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ForgotPasswordUIProps extends FormikProps<ForgotPasswordValues> {}

function ForgotPasswordUI({
  values,
  handleChange,
  handleSubmit,
  touched,
  errors,
  handleBlur,
  isSubmitting,
}: ForgotPasswordUIProps) {
  const { isMobile } = usePlatformDetect();
  return (
    <div
      className={classNames(
        isMobile() ? "flex-col" : "flex-row h-[calc(100vh-64px)]",
        "flex w-full",
      )}
    >
      <div className="w-full text-center text-white py-14 md:block md:py-28 bg-brown-800">
        <div className={`flex flex-col justify-center h-full animate-slidein`}>
          <div className="mb-3 text-2xl font-semibold md:text-4xl">
            <h1 className="">Create New Password</h1>
            <div className="p-3 text-base md:text-xl">
              Enter your email and we will send you a link to create your new
              password.
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center w-full text-center rounded-md">
        <form
          className="flex flex-col px-4 py-8 md:p-16"
          onSubmit={handleSubmit}
        >
          <div className="relative w-full">
            <TextInput
              label="Work Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              errorMessage={errors.email}
              touched={touched.email}
              variant="responsive"
              required
            ></TextInput>
          </div>

          <div className="mt-12">
            <Button
              type={ButtonType.Submit}
              variant={ButtonVariant.Orange}
              size={ButtonSize.Normal}
              className="w-full"
              disabled={isSubmitting}
            >
              Send Link
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPasswordUI;
