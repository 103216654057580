/** @format */

import { useMutation } from "@apollo/client";
import { appMissingDetailsSchema } from "@roadflex/constants";
import { MISSING_DETAILS_UPDATE } from "@roadflex/graphql";
import { einFormatter } from "@roadflex/web-lib";
import { useFormik } from "formik";
import { useRouter } from "next/router";
import { Toast } from "../toast-message/toast";
import { MissingDetailsUpdateUI } from "./missing-details-update.ui";

export const MissingDetailsUpdate = ({
  ssn,
  ein,
}: {
  ssn: string;
  ein: string;
}) => {
  const [missingDetailsUpdateFn, { loading: UpdatingDetails }] = useMutation<{
    missingDetailsUpdate: {
      code: string;
      message: string;
    };
  }>(MISSING_DETAILS_UPDATE, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });
  const router = useRouter();

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    submitForm,
    ...rest
  } = useFormik({
    initialValues: {
      ssn: ssn?.slice(-4),
      ein: einFormatter(ein || ""),
    },
    validationSchema: appMissingDetailsSchema,
    async onSubmit(value) {
      try {
        const res = await missingDetailsUpdateFn({
          variables: {
            data: {
              ssn: value.ssn.replace(/\D/g, ""),
              ein: value.ein,
            },
          },
        });
        if (res?.data?.missingDetailsUpdate?.code !== "200") {
          Toast({
            type: "error",
            message: res?.data?.missingDetailsUpdate?.message || "",
          });
          return;
        }
        router.push("/signin");
      } catch (err) {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        }
      }
    },
  });

  return (
    <MissingDetailsUpdateUI
      {...{
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        UpdatingDetails,
        errors,
        touched,
        setFieldValue,
        submitForm,
        ...rest,
        ssn,
        ein,
      }}
    ></MissingDetailsUpdateUI>
  );
};
