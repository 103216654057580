/** @format */
import { useMutation } from "@apollo/client";
import { wwwEmailValidationSchema } from "@roadflex/constants";
import { codeInternalServer } from "@roadflex/errors";
import { PASSWORD_FORGOT } from "@roadflex/graphql";
import { ForgotPasswordValues } from "@roadflex/types";
import { useFormik } from "formik";

type useSetPasswordProps = {
  onSubmit: (val: { code: string; message: string }) => void;
  initialValues: ForgotPasswordValues;
};

export const usePasswordForgot = ({
  onSubmit,
  initialValues,
}: useSetPasswordProps) => {
  const [mutationFn] = useMutation(PASSWORD_FORGOT);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: wwwEmailValidationSchema,
    onSubmit: async (value) => {
      try {
        const res = await mutationFn({ variables: { data: value } });
        onSubmit(res?.data?.passwordForgot);
      } catch (error) {
        if (error instanceof Error) {
          onSubmit({ code: codeInternalServer, message: error.message });
        } else {
          onSubmit({
            code: codeInternalServer,
            message: "An error has occurred",
          });
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
