import { useMutation } from "@apollo/client";
import { ENUM_SUBSCRIBER_SOURCE } from "@prisma/client";
import { wwwEmailValidationSchema } from "@roadflex/constants";
import { CREATE_SUBSCRIBER_LANDING_PAGE } from "@roadflex/graphql";
import { useFormik } from "formik";

type useCreateSubscriberProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: {
    email: "";
    source: ENUM_SUBSCRIBER_SOURCE;
  };
};

export const useCreateSubscriber = ({
  onSubmit,
  initialValues,
}: useCreateSubscriberProps) => {
  const [createSubscriberFn] = useMutation<{
    createSubscriber: {
      code: string;
      message: string;
    };
  }>(CREATE_SUBSCRIBER_LANDING_PAGE);

  const { ...rest } = useFormik({
    initialValues,
    validationSchema: wwwEmailValidationSchema,
    onSubmit: async (value) => {
      try {
        const res = await createSubscriberFn({
          variables: { data: value },
        });
        if (res?.data?.createSubscriber) {
          onSubmit(res?.data?.createSubscriber, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    ...rest,
  };
};
