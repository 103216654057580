/** @format */
import { useMutation } from "@apollo/client";
import { wwwGenericFormSchema } from "@roadflex/constants";
import { LANDING_PAGE_FORM_SUBMISSION } from "@roadflex/graphql";
import { LandingPageFormSubmissionValues } from "@roadflex/types";
import { useFormik } from "formik";

//AffiliateProgramValidationSchema, AffiliateProgramValidationSchema, PartnershipsValidationSchema, SavingsValidationSchema, contactUsValidationSchema

type UseLandingPageFormSubmissionProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    error: Error | null,
  ) => void;
  initialValues: LandingPageFormSubmissionValues;
};

export const useLandingPageFormSubmission = ({
  onSubmit,
  initialValues,
}: UseLandingPageFormSubmissionProps) => {
  const [landingPageFormSubmissionFn] = useMutation<{
    landingPageFormSubmission: {
      code: string;
      message: string;
    };
  }>(LANDING_PAGE_FORM_SUBMISSION);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: wwwGenericFormSchema,
    onSubmit: async (value) => {
      try {
        const inputs = {
          ...value,
          vehicles: Number(value?.vehicles),
        };
        const res = await landingPageFormSubmissionFn({
          variables: { data: inputs },
        });
        if (res?.data?.landingPageFormSubmission) {
          onSubmit(res?.data?.landingPageFormSubmission, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};
